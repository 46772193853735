import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import "./homeBar.css";
import Header from "../../components/header/Header";
import Category from "../../components/category/Category";
import ProductCards from "../../components/productCard/ProductCards";
import Slider from "react-slick";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { FiLayout } from "react-icons/fi";
import DisCountSlider from "../../components/discountBanner/DisCountSlider";
import { Images } from "../../assets/Images";
import AiSteps from "../../components/AIsteps/AiSteps";
import Testimonials from "../../components/testimonials/Testimonials";
import PerSearch from "../../components/PersonalizedSearch/PerSearch";
import Footer from "../../components/footer/Footer";
import { productSliderSettings } from "../../config/slickslider";
import SubHeader from "../../components/subHeader/SubHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../redux/slices/productsSlice";
import Layout from "../../components/Layout";
import { LuLayoutList } from "react-icons/lu";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { useTranslate } from "../../hooks/useTranslate";

const HomeBar = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.productSlice);

  useEffect(() => {
    dispatch(fetchProducts({}));
  }, [dispatch]);

  return (
    <>
      <Layout>
        {/* <SubHeader /> */}
        {/* home banner starts*/}
        <Container fluid>
          <div className="home">
            <div className="home-content ">
              <span className="h2 fw-bolder">
                {useTranslate("Leading B2B E-commerce Platform")}
              </span>
              <p className="py-3 " style={{ fontSize: "20px" }}>
                {useTranslate(
                  "Explore more then thousands products and get best AI filtration experience answer few question and AI will let you know best products."
                )}
              </p>
              <div className="ask-ai-box">
                <input
                  type="text"
                  placeholder={useTranslate("Search Keywords")}
                />
                <button className="btn btn-primary  btn-font">
                  {useTranslate("Ask AI")}
                </button>
              </div>
            </div>
          </div>
        </Container>
        {/* home banner ends */}

        {/* category slider starts */}
        {/* <Category /> */}
        {/* category slider ends */}

        {/* product cards slider */}
        {/* <Container fluid className="my-5">
        <span className="h3 ps-3">Popular Products</span>
        <Slider {...productSliderSettings} className="home-carousel">
          <ProductCards className="mx-3" />
          <ProductCards className="mx-3" />
          <ProductCards className="mx-3" />
          <ProductCards className="mx-3" />
          <ProductCards className="mx-3" />
          <ProductCards className="mx-3" />
          <ProductCards className="mx-3" />
          <ProductCards className="mx-3" />
        </Slider>
      </Container> */}
        <Container fluid className="my-5">
          <span className="h3 ps-3 fw-bold">
            {useTranslate("Popular Products")}
          </span>
          <Slider {...productSliderSettings} className="home-carousel">
            {products?.data &&
              products?.data?.map((product) => (
                <ProductCards
                  key={product.id}
                  className="mx-3"
                  data={product}
                />
              ))}
          </Slider>
        </Container>
        {/* product cards slider ends */}

        {/* cutting-edge solutions banner content starts*/}
        <Container fluid>
          <div className="cutting-edge-ai my-5">
            <span className="custom-text-primary h3 fw-bold">
              {useTranslate("Your Gateway to Cutting-Edge AI Solutions")},
              <br />
              {useTranslate("All in One Place")}
            </span>
            <Row>
              <Col lg={`4`} className="my-4">
                <FiLayout size={32} color="#1A3A66" />
                <span
                  className="custom-text-primary py-3 fw-bold  d-flex flex-column"
                  style={{ fontSize: "23px" }}
                >
                  {useTranslate("Extensive Categories")}
                </span>
                <p style={{ fontSize: "20px" }}>
                  {useTranslate(
                    "With over 100 categories, our platform ensures you find the specific AI solution you need. From machine learning to computer vision, we cover a broad spectrum to meet all your AI requirements."
                  )}
                </p>
              </Col>
              <Col lg={`4`} className="my-4">
                <LuLayoutList size={32} color="#1A3A66" />
                <span
                  style={{ fontSize: "23px" }}
                  className="custom-text-primary py-3 fw-bold fs-4 d-flex flex-column"
                >
                  {useTranslate("Advanced Comparison Tools")}
                </span>
                <p style={{ fontSize: "20px" }}>
                  {useTranslate(
                    "Our advanced comparison tools let you evaluate different AI platforms side-by-side. Compare features, pricing, and user reviews to make informed decisions, ensuring transparency and the best fit for your needs."
                  )}
                </p>
              </Col>
              <Col lg={`4`} className="my-4">
                <VscWorkspaceTrusted size={32} color="#1A3A66" />
                <span
                  style={{ fontSize: "23px" }}
                  className="custom-text-primary py-3 fw-bold fs-4 d-flex flex-column"
                >
                  {useTranslate("Trusted and Verified Providers")}
                </span>
                <p style={{ fontSize: "20px" }}>
                  {useTranslate(
                    "We partner with leading AI companies recognized for innovation and reliability. Each provider undergoes rigorous verification to meet our high standards, ensuring peace of mind when selecting an AI solution."
                  )}
                </p>
              </Col>
            </Row>
          </div>
        </Container>
        {/* cutting-edge solutions banner content ends */}

        {/* discount-banner starts */}
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Col lg={`7`} className="equal">
              <DisCountSlider />
            </Col>
            <Col lg={`5`} className="equal bg-violet">
              <div className="bg-violet-2 d-flex flex-column justify-content-center">
                <img src={Images.searchMusic} alt="search" />
                <span className="py-3 h2">
                  {useTranslate("Get The Best Result With The help of AI")}
                </span>
                <p className="" style={{ fontSize: "20px" }}>
                  {useTranslate(
                    "AI will ask questions to know more about your product,You can compare these products and buy best seller"
                  )}
                </p>
                <div>
                  <button className="btn border-0 px-5 py-2 btn-primary">
                    {useTranslate("Ask AI")}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* discount-banner ends */}

        {/* find best deal with AI-starts */}
        <Container fluid>
          <AiSteps />
        </Container>
        {/* find best deal with AI-ends */}

        {/* testimonials - starts */}
        <Container fluid>
          <Testimonials />
        </Container>
        {/* testimonials - ends */}

        {/* Personalized Search - starts */}
        <Container fluid>
          <PerSearch />
        </Container>
        {/* Personalized Search - ends */}
      </Layout>
    </>
  );
};

export default HomeBar;
