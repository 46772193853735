// import React from "react";
// import { FaStar } from "react-icons/fa";
// import "./productCard.css";
// import { Images } from "../../assets/Images";
// import { useNavigate } from "react-router-dom";

// const ProductCards = ({className}) => {

//   const navigate = useNavigate()

//   function handleNavigate(){
//     navigate(`/product-details/1`)
//   }
//   return (
//     <div className={`product-card ${className} my-3`} onClick={handleNavigate}>
//       <img src={Images.productimg} className="w-100" alt="product" />
//       <div className="stars py-1 d-flex align-items-center">
//         <FaStar className="star" />
//         <FaStar className="star" />
//         <FaStar className="star" />
//         <FaStar className="star" />
//         <FaStar className="star" />
//         <span className="text-muted ms-2">
//           <strong> 4.5 </strong> (112 reviews)
//         </span>
//       </div>
//       <span className="text-muted h5 fw-bold ">Canon EOS RP Body</span>
//       <div>
//         <span className="price">$450</span>{" "}
//         <span className="text-muted ms-2">
//           <s>$900.00</s>
//         </span>
//       </div>
//     </div>
//   );
// };

// export default ProductCards;
import React from "react";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./productCard.css";
import { useTranslate } from "../../hooks/useTranslate";

const ProductCards = ({ className, data }) => {
  const navigate = useNavigate();
  // const { name , price , image_path , id } = data

  function handleNavigate() {
    navigate(`/product-details/${data?.id}`);
  }

  return (
    <div className={`product-card ${className} my-3`} onClick={handleNavigate}>
      <img src={data?.image_path} className="w-100 mb-3" alt="product" />
      <span
        style={{ fontFamily: "cera", fontSize: "20px" }}
        className="text-muted fw-bold"
      >
        {useTranslate(data?.name)}
      </span>
      <div>
        <span
          style={{ fontFamily: "cera", fontSize: "18px" }}
          className="price "
        >
          ${data?.price}
        </span>
      </div>
    </div>
  );
};

export default ProductCards;
