import Footer from "../footer/Footer";
import Header from "../header/Header";
// import "../../App.css";

const Layout = ({ children }) => {
  return (
    <div className="main-container">
      <Header />
      {children}
      <Footer />
    </div>
  );
};
export default Layout;
